<template>
  <div class="step1">
    <div
      v-for="(gameType, index) in gameTypes"
      :key="gameType._id"
    >
      <XNav v-if="gameTypes.length > 1" :content="[{ t: `pug.${gameType.Type}` }]"/>
      <div class="upcoming-events">
        <div ref="eventbox">
          <div
            v-for="game in gameType.games"
            :key="game._id"
            v-bind:class="game.NumPlayers == game.MaxPlayers ? 'event disabled' : selectedGames.includes(game) ? 'event selected' : 'event'"
            :ref="`event`"
            @click="game.NumPlayers < game.MaxPlayers && $emit('updateSelection', game)"
          >
            <div class="game">
              <div class="info">
                <p class="text">{{ game.Type }} {{ $t("common."+game.Gender.toLowerCase()) }}</p>
                <p class="text">{{ $t("common."+game.Field.type.toLowerCase()) }}</p>
                <p class="text">{{ game.Field.Name }}</p>
                <p class="text">{{ game.Periods && game.Periods != 1 && `${game.Periods} x ` }} {{ `${game.Duration} min` }} </p>
                <p class="text" v-if="game.NumPlayers == game.MaxPlayers" style="color: red"> {{`${$t("pug.full")}!`}} </p>
              </div>
            </div>
            <div class="extra">
              {{
                $DateTime
                  .fromISO(game.Date)
                  .setLocale($i18n.locale)
                  .toFormat("MMM d h:mm a")
              }}
            </div>
          </div>
        </div>
        <button class="btn-left" @click="scrollEvents(-1, index)" >&#10096;</button>
        <button class="btn-right" @click="scrollEvents(1, index)" >&#10097;</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import XNav from "../../components/XNav.vue";
export default {
  name: "PUG-Step1",
  components: {
    XNav,
  },
  props: {
    selectedGames: Array
  },
  data() {
    return {
      gameTypes: [],
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      axios
        .get(`${this.$apiUrl}/pug/games`)
        .then((response) => {
          this.gameTypes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    scrollEvents(value, index) {
      this.$refs["eventbox"][index].scrollLeft +=
        (this.$refs["eventbox"][index].firstElementChild.offsetWidth + 10) * value;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/main.scss";

.step1 {
  margin-top: 50px;
}
.upcoming-events {
  position: relative;
  height: 180px;
  overflow-y: hidden;
  margin: 15px 0;

  & > div {
    margin: 0 30px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    height: 100%;
    display: flex;
    scrollbar-width: none;
    scroll-snap-type: x proximity;

    &::-webkit-scrollbar {
      height: 0 !important;
    }
  }

  button {
    position: absolute;
    top: 0;
    height: 180px;
    width: 30px;
    border: none;
    background: $primary;
    color: white;

    &:hover {
      background: darken($primary, 2);
    }

    &.btn-right {
      right: 0;
    }

    &.btn-left {
      left: 0;
    }
  }
}

.event {
  cursor: pointer;
  @extend %light-pattern;
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  margin: 0 5px;
  scroll-snap-align: start;
  scroll-margin: 0 5px;
  display: flex;
  flex-direction: column;


  &:hover {
    filter: brightness(0.9);
  }
}

.event.disabled {
  cursor: not-allowed;
  filter: opacity(0.5);
}
.event.selected {
  filter: brightness(0.5);
}

.game {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  border: 2px solid $secondary;
}

.img {
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 10px 10px 0 10px;
  max-height: 73px;
  width: fit-content;
  align-self: center;
}

.info {
  align-self: center;
  font-size: 18px;
  width: 80%;
  text-align: center;
  color: $secondary;
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px 10px 10px;

  .text{
    margin-top: 5px;
  }
}

.extra {
  background: $secondary;
  color: white;
  text-align: center;
  font-size: 15px;
  padding: 5px;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .upcoming-events {

    & > div {
      margin: 0;
    }

    button {
      display: none;
    }
  }
}
</style>
