<template>
  <div class="pug_container">
    <div class="pug_form">
      <h2 class="header">{{ $t("pug.register") }}</h2>
      <select
        v-model="gamePlayer.Level"
        class="selection"
      >
        <option selected disabled value="">
          {{ $t("pug.level") }}
        </option
        >
        <option
          v-for="level in levels"
          :key="level"
          :value="level.split('.')[2]"
          >{{ $t(level) }}
        </option
        >
      </select>
      <label class="label">{{ $t(`pug.preferred_pos`) }}:</label>
      <div class="checkbox positions" v-for="position in positions" :key="position">
        <input type="checkbox" :id="`cb-gp-${position}`" @change="updatePositions(gamePlayer, position)"/>
        <label :for="`cb-gp-${position}`">{{ getPosLabel(position) }}</label>
      </div>
      <div class="checkbox">
        <input type="checkbox" id="checkbox" v-model="gamePlayer.isCaptain" />
        <label for="checkbox">{{ $t("pug.team_captain") }}</label>
        <div class="tooltip">
          <svg-icon icon="info" class="infoIcon"/>
          <span class="tooltiptext">{{ $t("pug.team_captain_info") }}</span>
        </div>
      </div>
      <div class="guestForm" v-for="(guest, index) in guests" :key="index">
        <h2 class="header">
          {{`${$t("pug.guest")}  ${index + 1}`}}
          <img src="../../assets/icons/white-close.png" class="deleteIcon" @click="removeGuest(index)"/>
        </h2>
        <input
          type="text"
          class="input"
          :placeholder="$t('common.firstname')"
          v-model="guest.First_Name"
          required
        />
        <input
          type="text"
          class="input"
          v-model="guest.Last_Name"
          :placeholder="$t('common.lastname')"
          required
        />
        <div class="date">
          <label for="dateOfBirth">{{ $t('common.date_of_birth') }}</label>
          <input type="date" id="dateOfBirth" name="dateOfBirth" v-model="guest.Date_of_Birth">
        </div>
        <select
          v-model="guest.Level"
          class="selection"
        >
          <option selected disabled value="">
            {{ $t("pug.level") }}
          </option
          >
          <option
            v-for="level in levels"
            :key="level"
            :value="level.split('.')[2]"
            >{{ $t(level) }}
          </option
          >
        </select>
        <label class="label">{{ $t(`pug.preferred_pos`) }}:</label>
        <div class="checkbox positions" v-for="position in positions" :key="position">
          <input type="checkbox" :id="`cb-g${index}-${position}`" @change="updatePositions(guest, position)"/>
          <label :for="`cb-g${index}-${position}`">{{ getPosLabel(position) }}</label>
        </div>
      </div>
      <button class="btn guestButton" v-if="guest_limit!=0" @click="addGuest" :disabled="guests.length > guest_limit - 1">
        <span>
          <img src="../../assets/icons/plus-white.png" class="icon"/>
          {{ $t("pug.add_guest") }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step2",
  props: {
    gamePlayer: Object,
    guests: Array,
    guest_limit: Number,
    discounts: Object
  },
  data() {
    return {
      positions: [
        "pug.positions.gk",
        "pug.positions.df",
        "pug.positions.mf",
        "pug.positions.fw",
      ],
      levels: [
        "pug.levels.beginner",
        "pug.levels.intermediate",
        "pug.levels.advanced",
      ],
    };
  },
  methods: {
    addGuest(){
      this.guests.push({
        First_Name: "",
        Last_Name: "",
        Date_of_Birth: "",
        Preferred_pos: [],
        Level: "",
      });
    },
    removeGuest(index){
      this.guests.splice(index, 1);
    },
    getPosLabel(position){
      let label = this.$t(position);
      let pos = position.split('.')[2];
      let discountLabel = Number(this.discounts[pos]) ? '('+ this.discounts[pos] * 100 + '% ' + this.$t("pug.discount")+')' : "";
      return label + ' ' + discountLabel;
    },
    updatePositions(player, position){
      if(player.Preferred_pos.includes(position)){
        player.Preferred_pos.splice(player.Preferred_pos.indexOf(position), 1);
      }else{
        player.Preferred_pos.push(position);
      }
      player.Preferred_pos.sort((a,b) => this.positions.indexOf(a) - this.positions.indexOf(b));
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import "@/theme.scss";
  .pug_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .header{
    @extend %secondary-pattern;
    position: relative;
    text-align: center;
    width: 100%;
    padding: 10px 0;
  }
  .pug_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 100%;
    width: 30%;
    min-width: 335px;
    margin-top: 5%;
  }

  .input {
    border: 0;
    padding: 5px;
    border-bottom: 1px solid #ccc;
    height: 37px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  .selection {
    border: 0;
    border-bottom: 1px solid rgb(66, 66, 66);
    height: 37px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;

    label {
      margin-left: 10px;
    }
  }

  .positions {
    margin-top: 0;
    margin-left: 10%;
  }

  .label {
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 20px;
    align-self: flex-start;
    margin-left: 5px;
  }

  .btn{
    @extend %secondary-pattern;
    cursor: pointer;
    display: block;
    padding: 10px;
    width: 100%;
    min-width: 100px;
    border: 0px solid black;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px;
    font-size: 18px;
    color: white;
  }

  button:disabled{
    cursor: not-allowed;
    filter: opacity(0.7);
  }

  .guestButton {
    padding: 10px 20px;
    text-align: center;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .icon {
      width: 18px;
      height: 18px;
      margin-right: 10px;
      } 
    }
  }

  .deleteIcon {
    width: 25px;
    height: 25px;
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 25%;
  } 
  .guestForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;

    .date {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      border: 0;
      border-bottom: 1px solid rgb(66, 66, 66);
      height: 37px;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;

      label {
        margin-right: 10px;
      }
    }
  }

.infoIcon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: block;
}

.tooltip .tooltiptext {
  margin-left: 5px;
  visibility: hidden;
  width: 280px;
  background-color: #F3EFE9;
  color: black;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
  border: 1px solid #ccc;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  margin-top: 5px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (max-width: 540px) {
  .tooltip .tooltiptext {
    width: 200px;
    margin-left: -120px;
    top: 0;
    margin-top: -80px;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

</style>