<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header v-if="includeHeader" class="modal-header">
        <slot name="header"></slot>
        <button
          v-if="includeCloseButton"
          type="button"
          class="btn-close"
          @click="close"
          aria-label="Close modal"
          > x </button>
      </header>
      <section class="modal-body">
        <slot name="body">
          This is the default body!
        </slot>
       </section>
      <footer v-if="includeFooter" class="modal-footer">
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Modal',
    props: {
      includeHeader: {
        type: Boolean,
        default: true,
      },
      includeFooter: {
        type: Boolean,
        default: true,
      },
      includeCloseButton: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: auto;
    height: auto;
  }

  .modal-header {
    display: flex;
    position: relative;
    border-bottom: 1px solid #eeeeee;
    justify-content: space-between;
  }

  .modal-footer {
    padding: 15px;
    display: flex;
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    max-height: 70vh;
    overflow-y: auto;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #FFFFFF;
    background: transparent;
  }

</style>