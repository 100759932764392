<template>
  <div>
    <ReviewTable :content="content"/>
    <div class="checkbox">
      <input type="checkbox" id="checkbox" v-model="accepted" @click="$emit('onCheck')"/>
      <label for="checkbox"> {{ $t("pug.terms_and_conditions.checkbox")}} 
        <button class="link" @click="() => modalVisible = true">{{ $t("pug.terms_and_conditions.title") }}</button>
      </label>
    </div>
    <div class="totalContainer">
      <div class="promo-container" v-if="promo_codes_enabled">
        <h3 class="promo-label"> {{`${$t("pug.promo_code")}: `}}</h3>
        <div class="promo-input-container">
          <input type="text" v-model="promoCode" @input="updatePromoCode()" placeholder="COUPON" :class="'promo-input '+getPromoClass()"/>
          <p class="promo-success" v-if="promoCode !== '' && promo_valid">Promo Code Applied</p>
          <p class="promo-error" v-if="promoCode !== '' && !promo_valid">Invalid Promo Code</p>
        </div>
      </div>
      <span class="total">{{`${$t("pug.total")}: ${formatNumber(total)} ${$t("common.currency")}`}}</span>
    </div>
    <div>
      <Modal v-show="modalVisible" :includeCloseButton="true" @close="closeModal">
        <template #header>
          <h2 class="header"> {{`${$t("pug.terms_and_conditions.title")}`}} </h2>
        </template>
        <template #body>
          <div class="terms">
            <p>{{ $t("pug.terms_and_conditions.text.intro") }}</p>
            <p class="bold">{{ $t("pug.terms_and_conditions.text.1") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.11") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.12") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.13") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.14") }}</p>
            <p class="bold">{{ $t("pug.terms_and_conditions.text.2") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.21") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.22") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.23") }}</p>
            <p class="bold">{{ $t("pug.terms_and_conditions.text.3") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.31") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.32") }}</p>
            <p class="bold">{{ $t("pug.terms_and_conditions.text.4") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.41") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.42") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.43") }}</p>
            <p class="bold">{{ $t("pug.terms_and_conditions.text.5") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.51") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.52") }}</p>
            <p class="text">{{ $t("pug.terms_and_conditions.text.conc") }}</p>
          </div>
        </template>
        <template #footer>
          <div class="checkbox">
            <input type="checkbox" id="modalCheckbox" v-model="accepted" @click="$emit('onCheck')"/>
            <label for="checkbox">{{`${$t("pug.terms_and_conditions.checkbox")} ${$t("pug.terms_and_conditions.title")}`}}</label>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import ReviewTable from "./ReviewTable.vue";
import Modal from "../../components/Modal.vue";
import { mapState } from "vuex";
export default {
  components: {
    ReviewTable,
    Modal,
  },
  props: ["content", "termsAccepted", "total", "promo_codes_enabled", "promo_valid"],
  data() {
    return {
      accepted: this.termsAccepted,
      modalVisible: false,
      promoCode: "",
    };
  },
  computed: {
    ...mapState(["isLoggedIn"]),
  },
  methods: {
    closeModal() {
      this.modalVisible = false;
    },
    resetPromo() {
      this.promoCode = "";
      this.updatePromoCode();
    },
    updatePromoCode() {
      this.promoCode = this.promoCode.toUpperCase().trim();
      this.$emit("onPromoCodeChange", this.promoCode);
    },
    getPromoClass() {
      if(this.promoCode === "") {
        return "";
      }else if(this.promo_valid) {
        return "valid";
      }else {
        return "err";
      }
    },
    formatNumber(number) {
      return (Math.round(number * 100) / 100).toFixed(2)
    },
  },
  watch: {
    isLoggedIn() {
      if(!this.isLoggedIn) this.resetPromo();
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

  .header{
    @extend %secondary-pattern;
    position: relative;
    text-align: center;
    width: 100%;
    padding: 10px 0;
  }

  .totalContainer {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
  }

  .promo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
  }

  .promo-label {
    font-size: 16px;
    margin-right: 10px;
  }

  .promo-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .promo-input {
    padding: 10px;
    border: 1px solid black;
    border-radius: 3px;
    font-size: 16px;
    width: 100px;
  }
  .promo-success {
    color: green;
    font-size: 12px;
  }

  .promo-error {
    color: red;
    font-size: 12px;
  }

  .valid {
    border: 1px solid green;
  }

  .err {
    border: 1px solid red;
  }
  .total {
    @extend %secondary-pattern;
    width: 15%;
    min-width: 100px;
    margin: 0 0 0 auto;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 15%;
    min-width: 100px;
    border: 0px solid black;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px;
    font-size: 18px;
    color: white;
    text-align: center;
  }

  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 100%;

    label {
      margin-left: 10px;
    }
  }

  .link{
    background: none;
    border: none;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }

  .terms {
    width: 60vw;
    min-width: 500px;
    max-height: 60vh;
    overflow-y: auto;
    margin: 10px;

    .bold {
      margin-top: 10px;
      @extend %font-bold;
    }

    .text {
      margin-left: 10px;
    }
  }

  @media only screen and (max-width: 600px) {
    .checkbox {
      width: 70vw;
      min-width: initial;
    }

    .header {
      font-size: 4.5vw;
    }

    .terms {
      width: 70vw;
      min-width: initial;
    }

    .total {
      font-size: 14px;
    }

    .promo-container {
      width: 40%;
    }

    .promo-label {
      font-size: 14px;
    }

    .promo-input {
      font-size: 14px;
    }
  }
</style>
