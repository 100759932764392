<template>
  <div>
    <ul class="tabs-list">
      <li
        v-for="(tab, index) in xTabs"
        :key="index"
        :class="{ current: currentTab == index, disabled: isDisabled(index) && currentTab != index}"
        @click="$emit('updateTab', index)"
      >
      <p class="title">{{$t(tab.display)}}</p>
      <p class="info">{{ $t(tab.info) }}</p>
    </li>
    <div class="shape arrow-chevron to-right"></div>
    </ul>
    <div v-for="(tab, index) in xTabs" :key="index" v-show="currentTab == index">
      <slot :name="'tab['+tab.name+']'"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: { 
    xTabs: Array,
    currentTab: Number,
    isDisabled: Function
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
.tabs-list {
  display: flex;
  list-style-type: none;
  color: white;
  margin: 0 -3px;
  & > li {
    @extend %secondary-pattern;
    @extend %font-bold;
    width: 100%;
    font-size: 22px;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
    padding: 5px;
    cursor:pointer;
    margin: 0 3px;
    
    &.current{
      @extend %primary-pattern;
    }
    &.disabled{
      cursor: not-allowed;
      filter: opacity(0.7);
    }
  }
  & > li:not(.current):not(.disabled):hover {
    filter: brightness(0.7);
  }  
}
 

@media screen and (max-width: 600px) {

  .title {
    font-size: 18px;
    line-height: 25px;
  }
  .info {
    font-size: 12px;
    line-height: 15px;

  }
}
</style>