<template>
  <div>
    <XNav :content="[{ t: 'common.pug' }]"/>
    <transition name="pop" appear>
      <Modal v-show="showRegistrationModal">
        <template #header>
          <h2 class="header"> {{`${$t("pug.register")}!`}} </h2>
        </template>
        <template #body>
          <Register v-if="isSignup" :isComponent="true" :showRegistrationModal="showRegistrationModal"/>
          <Login v-else 
          :isComponent="true" 
          :showRegistrationModal="showRegistrationModal" 
          :skipForm="isLoggedIn && !verified"/>
        </template>
        <template #footer>
          <button class="link" @click="toggleForm">
          {{isSignup 
          ? `${$t("pug.already_acc")}!`
          : `${$t("pug.no_acc")}!`}}
          </button>
        </template>
      </Modal>
    </transition>
    <StepsTabs :x-tabs="tabs" v-on:updateTab="updateTab($event)" :currentTab="currentTab" :isDisabled="tabIsDisabled" class="tabs">
      <template #tab[step1]>
        <Step1 :selectedGames="selectedGames" v-on:updateSelection="updateSelectedGames($event)"/>
      </template>
      <template #tab[step2]>
        <Step2 :gamePlayer="gamePlayer" :guests="guests" :guest_limit="guest_limit" :discounts="discounts"/>
      </template>
      <template #tab[step3]>
        <Step3 
        :content="reviewContent" 
        :termsAccepted="termsAccepted" 
        :total="total" 
        :promo_codes_enabled="promo_codes_enabled"
        :promo_valid="promoCodeValid"
        @onPromoCodeChange="verifyPromoCode($event)"
        @onCheck="toggleTerms" 
        />
      </template>
    </StepsTabs>
    <div class="bottomButtons">
      <button v-if="currentTab > 0" class="btn left" @click="() => currentTab-=1">{{ $t("pug.back") }}</button>
      <button v-if="currentTab < 2" class="btn right" @click="updateTab(currentTab+1)" :disabled="isDisabled(currentTab)">{{ $t("pug.next") }}</button>
      <button v-else class="btn right register" @click="setUpPayment()" :disabled="!termsAccepted">{{ $t("pug.register") }}</button>
    </div> 
    <div class="faq">
      <a class="faq_text" href="pug/faq" target="_blank">{{ $t("common.faq") }}</a>
    </div>
    <div class="errors" v-if="errors.length > 0">
      <ul>
        <p class="error" v-for="error in errors" :key="error.message">{{ $t("error.pug."+error.error, {player: error.player, guest: error.guest, game: error.game}) }}</p>
      </ul>
    </div>
    <Modal v-show="showModal && paymentIntent" :includeHeader="false" :includeFooter="false">
      <template #body>
        <CheckoutForm v-if="paymentIntent" :paymentIntent="paymentIntent" @success="register($event)" @close="hideModal"/>
      </template>
    </Modal>
  </div> 
</template>

<script>
import axios from "axios";
import StepsTabs from "@/components/StepsTabs.vue";
import XNav from "@/components/XNav.vue";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import CheckoutForm from "@/components/CheckoutForm.vue";
import Modal from "@/components/Modal.vue";
import Login from "../Login.vue";
import Register from "../Register.vue";
import { mapState } from "vuex";

export default {
  name: "PUG",
  components: {
    StepsTabs,
    XNav,
    Step1,
    Step2,
    Step3,
    CheckoutForm,
    Modal,
    Login,
    Register,
  },
  data() {
    return {
      showRegistrationModal: false,
      isSignup: false,
      currentTab: 0,
      tabs: [
        { name: "step1", display: "pug.step1", info: "pug.step1_info" },
        { name: "step2", display: "pug.step2", info: "pug.step2_info" },
        { name: "step3", display: "pug.step3", info: "pug.step3_info" },
      ],
      selectedGames: [],
      gamePlayer: {
        Preferred_pos: [],
        Level: "",
        isCaptain: false,
      },
      guests: [],
      reviewContent:[],
      total: 0,
      paymentIntent: null,
      showModal: false,
      errors: [],
      termsAccepted: false,
      guest_limit: 0,
      price: 0,
      discounts: {},
      promo_codes_enabled: false,
      promoCode: "",
      promoCodeValid: false,
      promo: null,
    };
  },
  computed: {
    ...mapState(["isLoggedIn", "user"]),
    verified(){
      return this.user && this.user.Verified;
    },
  },
  created() {
    this.loadConfig();
    this.authenticateUser();
  },
  methods: {
    loadConfig(){
      axios.get(`${this.$apiUrl}/config/pug`)
      .then((res) => {
        this.guest_limit = Number(res.data.guest_limit);
        this.price = res.data.price;
        this.discounts = res.data.discounts;
        this.promo_codes_enabled = res.data.promo_codes_enabled;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    authenticateUser(){
      if(this.isLoggedIn) {
        this.gamePlayer.Registered_Player_id = this.user._id;
        this.setUpReview();
        if(this.verified) this.showRegistrationModal = false;
      }else{
        this.gamePlayer.Registered_Player_id = null;
        if(this.currentTab==2) this.showRegistrationModal = true;
      }
    },
    toggleForm(){
      this.isSignup = !this.isSignup;
    },
    isDisabled(tab) {
      if (tab === 0) {
        return !this.checkStep1();
      }
      if (tab === 1) {
        return !this.checkStep2();
      }
      if (tab === 2) {
        return true;
      }
      return false;
    },
    tabIsDisabled(tab){
      for(let i=tab-1; i>=0; i--){
        if(this.isDisabled(i)){
          return true;
        }
      }
      return false;
    },
    checkStep1() {
      return this.selectedGames.length > 0;
    },
    checkStep2() {
      let players = [this.gamePlayer, ...this.guests]
      return !players.some((player) => {
        for(let param in player){
          if(param != "isCaptain" && param != "Registered_Player_id" && (!player[param] || player[param] === "" || player[param].length === 0)){
            return true;
          }
        }
      });
    },
    updateSelectedGames(game) {
      if (this.selectedGames.includes(game)) {
        this.selectedGames = this.selectedGames.filter((e) => e != game);
      } else {
        this.selectedGames.push(game);
      }
    },
    updateTab(tab) {
      this.errors = [];
      if (!this.tabIsDisabled(tab)) {
        if(tab === 2 && (!this.isLoggedIn || !this.verified)){
          this.showRegistrationModal = true;
        }
        else{
          this.currentTab = tab;
          if(tab === 2){
            this.setUpReview();
            this.verifyPromoCode(this.promoCode);
          }
        }
      }
    },
    setUpReview(){
      this.reviewContent = [];
      this.total = 0;
      if(this.user){
        this.gamePlayer = {
          ...this.gamePlayer, 
          First_Name: this.user.First_Name, 
          Last_Name: this.user.Last_Name,
        };
      }
      this.selectedGames.sort((a,b) => a.Date > b.Date);
      for(let game of this.selectedGames){
        game = `${game.Type} - ${game.Field.Name} - ${this.$DateTime.fromISO(game.Date).setLocale(this.$i18n.locale).toFormat("MMM d h:mm a")}`
        for(let player of [this.gamePlayer, ...this.guests] ){
          let discount = this.getHighestDiscount(player);
          let price = this.price * (1 - discount);
          this.total += price;
          this.reviewContent.push({
            Game: game,
            Player: `${player.First_Name} ${player.Last_Name} ${player.isCaptain ? "(C)" : ""}`,
            Position: player.Preferred_pos.map(pos => this.$t(pos)).join(', '),
            Price: (Math.round(price * 100) / 100).toFixed(2)
          });
        }
      }
      if(this.promo && this.promo.amountOff) this.total -= this.promo.amountOff/100;
      else if(this.promo && this.promo.percentOff) this.total *= (1-this.promo.percentOff/100)
      this.total = Math.round(this.total * 100) / 100
    },
    getHighestDiscount(player) {
      let positions = player.Preferred_pos.map(pos => pos.split(".")[2])
      let highestDiscount = 0;
      positions.forEach((position) => {
        const discount = parseFloat(this.discounts[position]);
        if (discount > highestDiscount) highestDiscount = discount;
      });
      return highestDiscount;
    },
    setUpPayment() {
      this.errors = [];
      let gamePlayer = {
        ...this.gamePlayer,
        Level: this.gamePlayer.Level,
        Preferred_pos: this.gamePlayer.Preferred_pos.map(pos => pos.split(".")[2]),
      }
      let guests = this.guests.map(guest => {
        return {
          ...guest,
          Level: guest.Level,
          Preferred_pos: guest.Preferred_pos.map(pos => pos.split(".")[2]),
        }
      })
      
      axios.post(`${this.$apiUrl}/pug/gamePlayers/pay`,
        {
          GamePlayer: gamePlayer,
          Guests: guests,
          Games: this.selectedGames,
          Total: this.total,
        }
      )
        .then((response) => {
          this.paymentIntent = response.data;
          this.showModal = true;
        })
        .catch((err) => {
          for(let error of err.response.data){
            error.message = this.$t("error.pug."+error.error, {player: error.player, guest: error.guest, game: error.game})
            if(!this.errors.includes(error.message)) this.errors.push(error);
          }
        });
    },
    register(paymentIntent) {
      this.$store.dispatch("pageLoad", true)
      let gamePlayer = {
        ...this.gamePlayer,
        Level: this.gamePlayer.Level,
        Preferred_pos: this.gamePlayer.Preferred_pos.map(pos => pos.split(".")[2]),
      }
      let guests = this.guests.map(guest => {
        return {
          ...guest,
          Level: guest.Level,
          Preferred_pos: guest.Preferred_pos.map(pos => pos.split(".")[2]),
        }
      })
      axios.post(`${this.$apiUrl}/pug/gamePlayers`,
        {
          GamePlayer: gamePlayer,
          Guests: guests,
          Games: this.selectedGames,
          PaymentIntent: paymentIntent,
          Promo: this.promo
        })
        .then(() => {
          this.$store.dispatch("pageLoad", false)
          this.$router.push({ name: "paymentSuccess"});
        })
        .catch(() => {
          let message = this.$t("error.unexpected")
          if(!this.errors.includes(message)) this.errors.push(message);
          this.$store.dispatch("pageLoad", false)
        });
    },
    hideModal(){
      this.showModal = false;
    },
    toggleTerms(){
      this.termsAccepted = !this.termsAccepted;
    },
    async verifyPromoCode(promoCode){
      this.promoCode = promoCode;
      await axios.post(`${this.$apiUrl}/pug/gamePlayers/promo/verify`,
      {
        code: this.promoCode, 
        user_id: this.gamePlayer.Registered_Player_id, 
        amount: this.computeTotal()
      })
      .then((res) => {
        this.promoCodeValid = res.data.valid;
        this.promo = res.data.promo;
      })
      .catch(() => {
        this.promoCodeValid = false;
        this.promo = null;
      });
      this.setUpReview();
    },
    computeTotal(){
      let total = [this.gamePlayer, ...this.guests].reduce((acc, player) => {
        let discount = this.getHighestDiscount(player);
        let price = this.price * (1 - discount);
        return acc + price;
      }, 0);
      return Math.round(total * this.selectedGames.length * 100) / 100;
    },
  },
  watch: {
    isLoggedIn(){
      this.authenticateUser();
    },
    verified(val){
      if(val) this.authenticateUser();
    },

  }
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.tabs {
  width: 100%;
  margin: 15px 0;
}

.btn{
  @extend %secondary-pattern;
  cursor: pointer;
  display: block;
  padding: 10px;
  width: 15%;
  min-width: 100px;
  border: 0px solid black;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px;
  font-size: 18px;
  color: white;
}

button:disabled{
  cursor: not-allowed;
  filter: opacity(0.7);
}

.bottomButtons {
  display: flex;
  flex-direction: row;
}

.right {
  margin: 20px 0 0 auto;
}

.left {
  margin: 20px auto 0 0;
}

.register {
  @extend %primary-pattern;
}

.faq {
  margin-top: 20px;
}
.faq_text {
  color: $secondary;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.errors{
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 20px;
}

.error{
  margin-top: 10px;
  color: red;
  font-size: 12px;
}

.header{
  @extend %secondary-pattern;
  position: relative;
  text-align: center;
  width: 100%;
  padding: 10px 0;
}
.link{
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}

@media only screen and (max-width: 600px) {
  .link{
    font-size: 14px;
  }
}

</style>