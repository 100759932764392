<template>
    <div class="box">
      <h2>Review</h2>
      <XTable :x-content="content" :x-columns="columns" />
    </div>
</template>
    
<script>
import XTable from "@/components/tables/XTable.vue";
export default {
  components: {
    XTable,
  },
  props: ["content"],
  data() {
    return {
      columns: [
        { t: "pug.game", 
          name: "Game",
        },
        { t: "pug.player", 
          name: "Player",
        },
        { t: "pug.position", 
          name: "Position",
        },
        { t: "pug.price", 
          name: "Price",
        },
      ],
    };
  },
};
</script>